<template>
  <section id="categorias">
    <b-row class="mb-5">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <template v-if="isContent">
          <h1>Podcast</h1>
          <h5 class="text-primary">
            {{ $t('listPod') }}
          </h5>
        </template>
        <template v-else>
          <h1>Podcast</h1>
          <h5 class="text-primary">
            {{ $t('listCat') }}
          </h5>
        </template>
      </b-col>
    </b-row>
    <!-- MENSAJE DE ALERTA PARA INDICAR LIMITE DE CATEGORÍAS -->
    <b-row>
      <b-col>
        <categories-alerts
          :show-alert="showAlert"
          :show-alert-content="showAlertContent"
        />
      </b-col>
    </b-row>
    <b-row>
      <!-- RUTA DE NAVEGACIÓN POR CATEGORÍAS -->
      <b-col>
        <b-breadcrumb>
          <b-breadcrumb-item
            v-for="(brc, index) in navItems"
            :key="brc.text + '_' + brc.id"
            :text="brc.text"
            :active="index === navItems.length - 1 ? true : false"
            @click="index !== navItems.length - 1 ? getElement(brc.id, link ? $route.params.link : null) : ''"
          />
        </b-breadcrumb>
      </b-col>
      <!-- BUTTON EDITAR CATEGORIA -->
      <b-col class="d-flex justify-content-end align-items-center">
        <div
          v-if="navItems.length > 1"
          class="mr-2"
        >
          <b-button
            v-b-tooltip.hover.top.v-primary="$t('contents.editCat')"
            variant="outline-primary"
            class="d-flex align-items-center"

            @click="routeToEditView(navItems[navItems.length - 1].id)"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              class="mr-50 cursor-pointer"
            />
            {{ $t('Edit') }}
          </b-button>
        </div>
        <!-- BUTTON VOLVER -->
        <div v-if="navItems.length > 1">
          <b-button
            variant="danger"
            @click="getElement(navItems[navItems.length - 2].id, link ? $route.params.link : null)"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- APARTADO ORDENACIÓN MANUAL -->
    <b-row class="mt-2 justify-content-end">
      <b-col
        v-if="manual"
        class="d-flex align-items-center"
      >
        <b-button
          variant="outline-warning"
          disabled
        >
          {{ $t("contents.manualSortMode") }}
        </b-button>
        <b-button
          class="ml-2"
          variant="success"
          @click="ordenar('MAN')"
        >
          {{ $t('contents.finalize') }}
        </b-button>
      </b-col>
      <b-col
        v-if="!isContent && !manual"
        class="d-flex"
        style="align-items: center;"
      >
        <router-link
          to="/contents/contents/search"
          class="mr-1"
        >
          <b-button variant="info">
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            {{ $t('Buscador') }}
          </b-button>
        </router-link>
      </b-col>

      <!-- SELECCIÓN DE CONTENIDOS MÚLTIPLES, FILTROS Y OPCIONES -->
      <b-col v-if="isContent && !manual">
        <b-row>
          <b-col>
            <b-form-checkbox
              v-model="seleccionar"
              switch
              :disabled="totalContents == 0 ? true : false"
            >
              {{ $t('activar')
              }}
              {{ $t('SelCont') }}
            </b-form-checkbox>
          </b-col>
          <b-col md="2">
            <b-form-select
              v-if="seleccionar"
              v-model="state"
              :options="optionsStatus"
            />
          </b-col>
          <b-col
            v-if="seleccionar"
            md="2"
          >
            <b-button
              v-if="choicesContents.length > 0 && state != null"
              variant="success"
              @click="status()"
            >
              {{ $t('dataGeneric.change') }}
            </b-button>
            <b-button
              v-else
              disabled
            >
              {{ $t('dataGeneric.change') }}
            </b-button>
          </b-col>
          <b-col
            v-if="seleccionar"
            md="2"
          >
            <b-dropdown
              right
              variant="primary"
              class="mr-1"
              :text="$t('resources.tableHeader.actions')"
            >
              <b-dropdown-item
                :disabled="choicesContents.length == 0 ? true : false"
                @click="actions = 'ADD'"
              >
                {{
                  $t('addToCategory') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="choicesContents.length == 0 ? true : false
                "
                @click="actions = 'MOV'"
              >
                {{ $t('contents.moveContent') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="choicesContents.length == 0 ? true : false
                "
                @click="actions = 'REM'"
              >
                {{ $t('removeFromCat') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="choicesContents.length == 0 ? true : false"
                variant="danger"
                @click="actions = 'DEL'"
              >
                {{ $t('dataGeneric.delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-checkbox
              v-if="seleccionar"
              v-model="todos"
              switch
            >
              {{ $t('slider.allSelect') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
      <!-- TIPO DE ORDENACIÓN DE LA CATEGORÍA -->
      <b-col
        md="4"
        class="d-flex"
        style="justify-content: flex-end;height: fit-content;"
      >
        <feather-icon
          v-if="isContent && !enlace"
          v-b-tooltip.hover.top.v-primary="orderType == 'MAN' ? $t('contents.sortManual') : orderType == 'EAS' ? $t('orderEmit') : $t('orderEmitRev')"
          icon="InfoIcon"
          class="mr-2"
          style="align-self: center;"
        />

        <!-- MODOS DE ORDENACIÓN SI ES O NO CONTENIDO-->
        <!--  <b-dropdown right v-if="!isContent || enlace" class="float-left mr-1" text="Ordenar">
          <b-dropdown-item @click="ordenar('manual')">Manualmente</b-dropdown-item>
        </b-dropdown> -->
        <b-dropdown
          v-if="isContent && !enlace"
          right
          class="float-left mr-1"
          :text="$t('contents.sort')"
        >
          <b-dropdown-item
            :disabled="contentData.categoryContent != null ? (totalContents == 0 ? true : false) : true"
            @click="ordenar('EAS')"
          >
            {{ $t('emit') }}
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="contentData.categoryContent != null ? (totalContents == 0 ? true : false) : true"
            @click="ordenar('EDE')"
          >
            {{ $t('emitReverse') }}
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="contentData.categoryContent != null ? (totalContents == 0 ? true : false) : true"
            @click="ordenar('ALF')"
          >
            {{ $t('alfa') }}
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="contentData.categoryContent != null ? (totalContents == 0 ? true : false) : true"
            @click="ordenar('MAN')"
          >
            Manual
          </b-dropdown-item>
        </b-dropdown>

        <!-- BUTTON ADD PARA CATEOGORÍAS DE TIPO ENLACE -->

        <div>
          <!-- BUTTON_GROUP CREAR CONTENIDO Y AÑADIR CONTENIDO -->
          <b-button-group
            v-if="isContent"
            class="float-right"
          >
            <b-dropdown
              variant="primary"
              right
              :text="$t('addPod')"
            >
              <b-dropdown-item @click="createNewContent">
                <feather-icon
                  icon="MicIcon"
                  class="mr-50"
                />
                {{ $t('createPod') }}
              </b-dropdown-item>
              <b-dropdown-item @click="showModal()">
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('existPod') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
          <!-- BUTTON CREAR NUEVA CATEGORÍA-->
          <b-button
            v-else
            :disabled="overlay ? true : false"
            variant="primary"
            @click="createNewCategory"
          >
            <feather-icon
              icon="FolderPlusIcon"
              class="mr-50"
            />
            <span class="text-nowrap">{{ $t('addCategory') }}</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- NÚMERO DE ELEMENTOS -->
    <b-row>
      <b-col class="d-flex justify-content-end mt-2">
        <span>
          <strong>{{ isContent ? totalContents : totalCategories }}</strong> {{ $t('contents.elements') }}
        </span>
      </b-col>
    </b-row>

    <!-- CONTENIDOS -->
    <div v-if="isContent && !enlace">
      <b-tabs
        v-model="tabIndex"
        class="mt-2"
        pills
        lazy
      >
        <b-tab>
          <template #title>
            <feather-icon icon="GridIcon" />
            <span>{{ $t('Cards') }}</span>
          </template>
          <contents-list-card
            :manual="manual"
            :actions="actions"
            :choice="seleccionar"
            :todos="todos"
            :code="code"
            :contenidos="contentData.categoryContent"
            @reload="reloadData"
            @choices="contChoice"
            @actReturn="actReturn"
            @vacio="vacio"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>{{ $t('List') }}</span>
          </template>
          <contents-table
            :code="code"
            :contenidos="contentData.categoryContent"
            :manual="manual"
          />
        </b-tab>
      </b-tabs>
    </div>
    <!-- CATEGORÍAS -->
    <b-tabs
      v-else
      v-model="tabIndex"
      pills
      lazy
    >
      <!-- GRID -->
      <b-tab>
        <template #title>
          <feather-icon icon="GridIcon" />
          <span>{{ $t('Cards') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="overlay"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-row>
            <b-col
              v-for="folder in childCategory"
              :id="folder.node.id + ' ' + folder.node.order"
              :key="folder.node.id"
              class="mt-5 "
              md="6"
              lg="3"
              xl="3"
              sm="12"
            >
              <b-card
                class=" carCat"
                no-body
              >
                <b-img
                  :src="buildImageUrl(folder)"
                  class="card-img-top cursor-pointer"
                  img-alt="Card image"
                  fluid-grow
                  @click="getElement(folder.node.id, folder.node.type)"
                  @error="errorImg"
                />
                <b-card-header>
                  <div id="acortarPadre">
                    <b-card-title
                      id="acortar"
                      class="mb-25"
                    >
                      <span
                        v-if="folder.node.state == 'GRE'"
                        class="mr-50 bullet bullet-success bullet-sm"
                      />
                      <span
                        v-if="folder.node.state == 'YEW'"
                        class="mr-50 bullet bullet-warning bullet-sm"
                      />
                      <span
                        v-if="folder.node.state == 'RED'"
                        class="mr-50 bullet bullet-danger bullet-sm"
                      />
                      {{
                        folder.node.name
                      }}
                    </b-card-title>
                    <b-card-text class="mb-0">
                      {{ $t("CPOD") }}
                    </b-card-text>
                  </div>
                </b-card-header>

                <b-card-body>
                  <div class="actions">
                    <div
                      v-clipboard:copy="feedPodcast"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="align-items-center"
                      @click.stop.prevent="generateXml(folder.node.id)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top.v-primary="$t('feedRssPodcast')"
                        icon="MicIcon"
                        class="ml-2 cursor-pointer"
                        size="18"
                      />
                    </div>
                    <div class="action">
                      <div>
                        <feather-icon
                          class="cursor-pointer"
                          icon="Edit2Icon"
                          size="20"
                          @click="routeToEditView(folder.node.id)"
                        />
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          right
                        >
                          <template #button-content>
                            <feather-icon
                              color="white"
                              icon="MoreVerticalIcon"
                              size="20"
                            />
                          </template>
                          <b-dropdown-item @click="deleteFolder(folder.node.id)">
                            <feather-icon
                              icon="DeleteIcon"
                              size="18"
                            />
                            {{ $t("contents.dropCat") }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-overlay>
      </b-tab>
      <!-- TABLE -->
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>{{ $t('List') }}</span>
        </template>
        <b-card>
          <b-table-simple
            ref="refContentListTable"
            show-empty
            hover
            selectable
            :select-mode="single"
            :empty-text="$t('emptyText')"
            responsive
            primary-key="id"
            :sticky-header="true"
            :no-border-collapse="true"
          >
            <b-thead>
              <b-tr>
                <b-th
                  v-for="i in tableColumns"
                  :key="i.key"
                >
                  {{ i.label }}
                </b-th>
              </b-tr>
            </b-thead>
            <draggable
              :v-model="enlace == false ? childCategory : linkCategory"
              tag="tbody"
              handle=".handle"
              :list="enlace == false ? childCategory : linkCategory"
              group="categories"
              :sort="orderEstructure"
            >
              <b-tr
                v-for="item in enlace == false ? childCategory : linkCategory "
                :key="item.node.id"
                @dragend="updateOrderTable"
              >
                <b-td @click="!manual ? onRowSelected(item) : ''">
                  <div
                    class="d-flex"
                    style="align-items: center"
                  >
                    <b-img
                      id="avatar"
                      class="mr-2 ml-2"
                      rounded="lg"
                      :src="buildImageUrl(item)"
                      @error="errorImg"
                    />
                    <p>{{ item.node.name }}</p>
                  </div>
                </b-td>

                <b-td @click="!manual ? onRowSelected(item) : ''">
                  {{ item.node.isFinal ? 'Final' : 'No final' }}
                </b-td>
                <b-td @click="!manual ? onRowSelected(item) : ''">
                  {{ $t(categoryTypes[item.node.type]) }}
                </b-td>
                <b-td>
                  <i
                    v-if="manual"
                    class="fa fa-align-justify handle cursor-move text-warning"
                  />

                  <b-dropdown
                    v-else
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        color="white"
                        icon="MoreVerticalIcon"
                        size="20"
                      />
                    </template>
                    <div v-if="enlace">
                      <b-dropdown-item @click="removeLink(item)">
                        <feather-icon
                          icon="DeleteIcon"
                          size="18"
                        />
                        {{ $t('dropLink') }}
                      </b-dropdown-item>
                    </div>
                    <div v-else>
                      <b-dropdown-item @click="routeToEditView(item.node.id, 0)">
                        <feather-icon
                          icon="Edit2Icon"
                          size="20"
                        />
                        {{ $t("contents.editCat") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteFolder(item.node.id)">
                        <feather-icon
                          icon="DeleteIcon"
                          size="18"
                        />
                        {{ $t("contents.dropCat") }}
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </b-td>
              </b-tr>
            </draggable>
          </b-table-simple>
        </b-card>
      </b-tab>
    </b-tabs>
    <!-- MODAL PARA SELECCIONAR CONTENIDOS -->
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('code.sel')"
    >
      <div class="d-block text-center">
        <content-selector
          :podcast="true"
          @data="SelectContent"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal(0)"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BFormSelect,
  BCol,
  BRow,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BButtonGroup,
  BDropdown,
  VBTooltip,
  BDropdownItem,
  BModal,
  BTabs,
  BTab,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardTitle,
  BImg,
  BCardText,
  BCardBody,
  BOverlay,
  BTableSimple,
  BTr,
  BThead,
  BTd,
  BTh,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, getInitialCategoriesLimit, getContentsLimit, utf8ToB64, checkRelationOrder, showToast, specialCategory,
} from '@/store/functions'
import store from '@/store'
import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import configCategoryType from '@core/utils/categoryType'
import ContentSelector from '@/views/common/ContentSelector.vue'
import CategoriesAlerts from '../categories/CategoriesAlerts.vue'
import ContentsListCard from './PodcastContentsCard.vue'
import ContentsTable from './PodcastContentsTable.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BFormSelect,
    ContentsListCard,
    ContentsTable,
    ContentSelector,
    BImg,
    BTabs,
    BTab,
    CategoriesAlerts,
    draggable,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BOverlay,
    BCol,
    BFormCheckbox,
    BModal,
    BRow,
    BTableSimple,
    BTr,
    BThead,
    BTd,
    BTh,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: getUserData(),
      feedPodcast: '',
      specialCategory,
      isPod: false,
      overlay: false,
      fromSyP: false,
      allNoFinalCategories: null,
      headers: {},
      brItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      navItems: [{
        id: null,
        text: this.$t('contents.start'),
      }],
      single: 'single',
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      choosedCategory: null,
      orderEstructure: true,
      seleccionar: false,
      todos: false,
      state: null,
      optionsStatus: [
        { value: null, text: this.$t('code.status') },
        { value: 'RED', text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      choicesContents: [],
      showAlert: false,
      showAlertContent: false,
      totalContents: 0,
      totalCategories: 0,
      tableColumns: [
        { key: 'name', label: this.$t('administrators.name') },
        { key: 'is_final', label: this.$t('catFinal') },
        { key: 'type', label: this.$t('contents.categoryType') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      categoryTypes: configCategoryType(true, false, false, false),
      isContent: false,
      idParent: null,
      actions: null,
      tabIndex: 0,
      manual: false,
      name: '',
      nameState: null,
      enlace: false,
      link: false,
      idEnlace: null,
      linkCategory: [],
      lastOrder: 0,
      orderType: 'MAN',
      childCategory: [],
      contentData: [],
      code: this.$route.params.id != null ? this.$route.params.id : null,
    }
  },
  watch: {
    totalCategories() {
      if (this.code != null && this.totalCategories === 0) {
        axios
          .post('', {
            query: `
        mutation{
        updateCategory(id:"${this.code}",input:{isActive:false,state: RED}) {
          category {
              id
              type
          }
        }
      }
        `,
          })
          .then(result => {
            messageError(result, this)

            this.$swal({
              title: this.$t('warning'),
              text: this.$t('advertencia'),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$t('next'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              showToast(this.$t('success'), 1, this)
            })
          })
          .catch(() => { })
      }
    },
    totalContents() {
      if (this.totalContents === 0) {
        axios
          .post('', {
            query: `
        mutation{
        updateCategory(id:"${this.code}",input:{isActive:false,state: RED}) {
          category {
              id
              type
          }
        }
      }
        `,
          })
          .then(result => {
            messageError(result, this)

            this.$swal({
              title: this.$t('warning'),
              text: this.$t('advertenciaCont'),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$t('next'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              showToast(this.$t('success'), 1, this)
            })
          })
          .catch(() => { })
      }
    },
    seleccionar() {
      if (!this.seleccionar) {
        this.choicesContents = []
      }
    },
    contentData() {
      try {
        if (this.isContent) {
          if (this.totalContents !== this.contentData.categoryContent.edges.length) {
            this.reloadData(this.code)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    /* LIMPIAR SELECCIÓN DE TODOS LOS CONTENIDOS AL CAMBIAR DE TABS */
    tabIndex() {
      this.todos = false
      /* TODO Por ahora se limpiara, pero más adelante mantendrá los contenidos y en el modo lista se podrán seleccionar también */
      this.choicesContents = []
    },
    /* OBTENER SI SE TRATA UNA CATEGORÍA DE ENLACES */

  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    if (this.code != null) this.getCategory(this.code)
    else this.fetchData()
  },
  methods: {
    getCheckCat(id) {
      axios
        .post('', {
          query: `
        {
        allCategories(id:"${id}",client:"${this.userData.profile.client.id}") {
          edges {
            node {
              id
              type
            }
          }
        }
      }
        `,
        })
        .then(result => {
          messageError(result, this)

          const { type } = result.data.data.allCategories.edges[0].node
          if (type === 'POD') {
            this.isPod = true
          }
        })
        .catch(() => { })
    },
    /* LLAMADA DE FUNCIONES DE ORDENACIÓN SEGÚN EL TIPO DE ORDENACIÓN SELECCIONADA */
    ordenar(type) {
      this.orderType = type

      switch (this.orderType) {
        case 'EAS':
          this.sortedCategory(this.orderType)
          break
        case 'EDE':
          this.sortedCategory(this.orderType)
          break
        case 'ALF':
          this.sortedCategory(this.orderType)
          break
        case 'MAN':
          this.manual = !this.manual
          break
        default:
          break
      }
    },
    sortedCategory(orderType) {
      axios
        .post('', {
          query: `
              mutation{
                sortedContents(categoryId:"${this.code}",orderType:"${orderType}"){
                  okCode
                }
              }
           `,
        }).then(result => {
          messageError(result, this)
          if (result.data.data.sortedContents.okCode === 2) {
            this.$swal({
              title: this.$t('noEmitionDate'),
              text: this.$t('infoEmit'),
              icon: 'warning',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {

            }).catch(() => {

            })
            showToast(this.$t('success'), 1, this)
          }
          if (result.data.data.sortedContents.okCode === 1) showToast(this.$t('success'), 1, this)
          this.reloadData(this.code)
        }).catch(() => {
          showToast(this.$t('error'), 0, this)
        })
    },
    /* MÉTODO PARA CAMBIAR EL TIPO DE ORDEN DE LA CATEGORÍA SEGÚN EL ORDEN SELECCIONADO: MAN,EDE,EAS */
    changeTypeOrder() {
      if (this.contentData != null) {
        axios
          .post('', {
            query: `
            mutation{
            updateCategory(id:"${this.code}",input:{orderType:${this.orderType}})  {
              category {
                id
              }
            }
            }
           `,
          }).then(result => {
            messageError(result, this)
          }).catch(err => {
            console.log(err)
          })
      }
    },

    /* MÉTODO PARA ACTUALIZAR EL ORDEN DE LOS CONTENIDOS SEGÚN LA NUEVA ORDENACIÓN */
    updateList(id) {
      const encondedCode = utf8ToB64(`id:${id}`)

      const query = `
        {
          allCategories(id:"${id}",type:POD) {
            totalCount
            edges {
              node {
                  id
                  name
                  order
                  description
                  image
                  imageUrl
                  mediaLocation
                  isFinal
                  isPremium
                  isActive
                  state
                  type

                  genre {
                    id
                    name
                  }
                  clasification {
                    id
                    type
                  }
                  categoryContent(orderBy:"order,-created_at"){
                  totalCount
                  edgeCount
                  edges
                  {
                    node
                    {
                      id
                      name
                      type
                      emisionDate
                      isPremium
                      hashtag
                      state
                      comment
                      modifiedAt
                      publishDate
                      expirationDate
                      isAds
                      isActive
                      order
                      mediaLocation
                      image
                      imageUrl
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      contentContentOrder(category:"${encondedCode}") {
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                  }
                  }
                }
                  childCategories{
                    edges {
                      node {
                        id
                        name
                        categoryContent(orderBy:"order,-created_at"){
                            totalCount
                            edgeCount
                            edges
                            {
                              node
                              {
                                id
                                name
                                type
                                emisionDate
                                isPremium
                                hashtag
                                state
                                comment
                                modifiedAt
                                publishDate
                                expirationDate
                                isAds
                                isActive
                                order
                                mediaLocation
                                image
                                imageUrl
                                contentContentOrder(category:"${encondedCode}") {
                                  totalCount
                                  edges {
                                    node {
                                      id
                                      order
                                      category {
                                        id
                                        name
                                      }
                                    }
                                  }
                                }
                                categories {
                                  edges {
                                    node {
                                      id
                                      name
                                    }
                                  }
                                }
                            }
                            }
                          }
                        description
                        type
                        isActive
                        state
                        isPremium
                        name
                        mediaLocation
                        image
                        imageUrl
                        
                        isFinal
                        genre {
                          id
                          name
                        }
                        clasification {
                          id
                          type
                        }
                        parent {
                          id
                          name
                          description
                          mediaLocation
                          image
                          imageUrl
                          treeId
                          level
                          isFinal
                        }

                      }
                    }
                  }
                  parent {
                    id
                    name
                    description
                    
                    isFinal
                  }
                }
              }
            }
          }`
      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          try {
            const catCont = res.data.data.allCategories.edges[0].node
            this.contentData = catCont

            this.contentData.categoryContent.edges = this.contentData.categoryContent.edges.sort(
              (a, b) => a.node.contentContentOrder.edges[0].node.order - b.node.contentContentOrder.edges[0].node.order,
            )
            // eslint-disable-next-line no-empty
          } catch (error) { }

          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          this.overlay = false
        })
    },
    /* METODO PARA REALIZAR LAS LLAMADAS DE LOS DISTINTOS MODALES SEGÚN SE NECESITE */
    showModal() {
      this.$refs['my-modal'].show()
    },
    /* METODO PARA CERRAR LOS MODALES SEGÚN SELECCIÓN */
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    /* RESPUESTA DEL MODAL DE CONTENIDOS */
    SelectContent(data) {
      this.hideModal()
      /* Ordenación del campo order de los contenidos de la categoría */
      const arrayNum = []
      const encondedCode = utf8ToB64(`id:${this.code}`)
      this.contentData.categoryContent.edges.forEach(element => {
        arrayNum.push(element.node.order)
      })
      arrayNum.sort()
      const newOrder = [0]
      if (arrayNum.length !== 0) {
        while (newOrder.length !== arrayNum.length) {
          const n = parseInt(newOrder[newOrder.length - 1], 10)
          newOrder.push(n + 1)
        }
      }

      const order = arrayNum.length === 0 ? 0 : (arrayNum[arrayNum.length - 1] + 1)
      /* Añadir nuevo contenido al principio de la categoría y su orden 0 */
      const arrayContents = []
      this.contentData.categoryContent.edges.forEach(element => {
        arrayContents.push(element.node.id)
      })
      arrayContents.unshift(data)
      newOrder.push(order)

      let query = ` mutation
              {`
      arrayContents.forEach((element, i) => {
        query += `cont${i + 1}: updateContents(id:"${element}",input:{order:${newOrder[i]}}){
                content{
                  id
                  order
                }
              }`
      })
      query += '}'
      /* Actualizar número de elementos */
      this.totalContents = arrayContents.length

      axios
        .post('', {
          query,
        }).then(result => {
          messageError(result, this)

          /* actualización de la categoría con todos los contenidos incluido el nuevo */
          axios
            .post('', {
              variables: {
                id: this.code,
                contents: arrayContents,
              },
              query: `
          mutation($id: ID!,$contents: [ID]){
              updateCategory(id: $id,input:{categoryContent:$contents}){
            category
              {
                  id
                  name
                  orderType
                  order
                  description
                  image
                  imageUrl
                  mediaLocation
                  treeId
                  level
                  isFinal
                  isPremium
                  isActive
                  type
                  genre {
                    id
                    name
                  }
                  clasification {
                    id
                    type
                  }
                  categoryContent(orderBy:"order,-created_at"){
                  totalCount
                  edgeCount
                  edges
                  {
                    node
                    {
                      id
                      name
                      type
                      emisionDate
                      isPremium
                      hashtag
                      state
                      comment
                      modifiedAt
                      publishDate
                      expirationDate
                      isAds
                      isActive
                      order
                      mediaLocation
                      image
                      imageUrl
                      contentContentOrder(category:"${encondedCode}") {
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                   }
                  }
                }
                  childCategories{
                    edges {
                      node {
                        id
                        name
                        categoryContent(orderBy:"order,-created_at"){
                            totalCount
                            edgeCount
                            edges
                            {
                              node
                              {
                                id
                                type
                                name
                                emisionDate
                                state
                                comment
                                modifiedAt
                                publishDate
                                expirationDate
                                isAds
                                isActive
                                order
                                mediaLocation
                                image
                                imageUrl
                                contentContentOrder(category:"${encondedCode}") {
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                                categories {
                                  edges {
                                    node {
                                      id
                                      name
                                    }
                                  }
                                }
                            }
                            }
                          }
                        description
                        type
                        isActive
                        state
                        isPremium
                        name                        
                        image
                        imageUrl                        
                        isFinal
                        genre {
                          id
                          name
                        }
                        clasification {
                          id
                          type
                        }
                        parent {
                          id                        
                          image
                          imageUrl
                        
                         isFinal
                        }

                      }
                    }
                  }
                  parent {
                    id
                    name
                    description
                  
                    isFinal
                  }
                }
              }
    }
        `,
            })
            .then(res => {
              messageError(res, this)

              /* actualización visual de los contenidos */
              const catCont = res.data.data.updateCategory.category
              this.contentData = catCont
              this.orderType = catCont.orderType

              localStorage.setItem(
                'levelNavigation',
                JSON.stringify(this.contentData),
              )

              checkRelationOrder(this.code).then(() => {
                this.reloadData(this.code)
              }).catch(err => {
                console.log(err)
              })
            })
            .catch(() => { })
        }).catch(() => {

        })
    },

    chooseCategory(event) {
      this.choosedCategory = this.childCategory[event.oldIndex]
    },
    /* MÉTODO PARA LLAMAR A LAS CATEGORÍAS ROOT DEL MENÚ INICIO */
    fetchData() {
      this.seleccionar = false
      this.todos = false
      const { headers } = this
      this.overlay = true
      axios
        .post('', {
          query: `
          query {
            allCategories(type:POD,client:"${this.userData.profile.client.id}"
            )  {
            totalCount
                    edges {
                      node {
                        id
                        name
                        orderType
                        order
                        image
                        imageUrl
                        mediaLocation
                        isFinal
                        isActive
                        state
                        type
                        parent {
                          id
                          name
                          isFinal
                        }
                      }
              }
          }
      }

        `,
        }, { headers })
        .then(response => {
          messageError(response, this)

          this.totalCategories = response.data.data.allCategories.totalCount
          try {
            const { result } = getInitialCategoriesLimit(
              response.data.data.allCategories.totalCount,
            )
            this.showAlert = result
            store.commit(
              'app/SET_CATEGORY_COPY',
              response.data.data.allCategories.edges,
            )

            this.childCategory = store.state.app.listOfCategories
            this.allNoFinalCategories = store.state.app.listOfCategories.filter(
              item => !item.node.isFinal,
            )
            this.contentData = null
            // eslint-disable-next-line no-empty
          } catch (error) { }

          try {
            this.childCategory = this.childCategory.sort((a, b) => (a.node.order - b.node.order))
            // eslint-disable-next-line no-empty
          } catch (error) {

          }
          this.overlay = false
        })
    },

    /* MÉTODO PARA CREAR NUEVAS CATEGORÍAS */
    createNewCategory() {
      try {
        const { result } = getInitialCategoriesLimit(this.childCategory.length)
        if (result) {
          this.showAlert = true
        } else {
          this.$router.push({ name: 'create-podcast' })
        }
      } catch (error) {
        // console.log(error)

        this.$router.push({ name: 'create-podcast' })
      }
    },
    /* MÉTODO PARA CREAR NUEVOS CONTENIDOS */
    createNewContent() {
      let total = 0
      axios
        .post('', {
          query: `
          query {
            allContents(client:"${this.userData.profile.client.id}") {
              totalCount
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          total = response.data.data.allContents.totalCount
        })

      const { result } = getContentsLimit(total)
      if (result) this.showAlertContent = true
      else {
        this.$router.push({
          name: 'podcast-create',
          params: { id: this.code },
        })
      }
    },
    /* FUNCIÓN PARA INSERTAR IMAGEN DE ERROR EN CASO DE QUE UNA IMÁGEN DE ERROR */
    errorImg(e) {
      e.target.src = fileError
    },
    /* MÉTODO PARA ELIMINAR CATEGORÍAS */
    deleteFolder(id) {
      this.overlay = true

      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
                mutation{
                  deleteCategory(id:"${id}") {
                    found
                    deletedId
                  }
                }`,
            })
            .then(res => {
              messageError(res, this)

              this.childCategory = this.childCategory.filter(item => item.node.id !== id)

              this.totalCategories = this.childCategory.length

              this.overlay = false
              showToast(this.$t('success'), 1, this)
            })
            .catch(res => {
              this.overlay = false
              showToast(this.$t('error'), 2, this)
              console.log(res)
            })
        } else {
          this.overlay = false
        }
      })
    },

    /* LLAMADA A LA RUTA DE EDICIÓN DE CATEGORÍAS */
    routeToEditView(categoryId) {
      this.$router.push({ name: 'podcast-category-edit', params: { id: categoryId } })
    },
    /* CLICK EN FILA DE TABLA */
    onRowSelected(items) {
      if (items.length !== 0) {
        if (specialCategory(items.node.type)) { this.catEspecial(items) } else { this.getElement(items.node.id, this.link ? this.$route.params.link : items.node.type) }
      }
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    updateOrderTable() {
      this.overlay = true
      const arrayNum = []
      const arrayId = []

      const data = this.enlace === false ? this.childCategory : this.linkCategory
      for (let i = 0; i < data.length; i += 1) {
        if (this.enlace) {
          arrayId.push(data[i].node.linkLinkCategory.edges[0].node.id)
          arrayNum.push(data[i].node.linkLinkCategory.edges[0].node.order)
        } else {
          arrayId.push(data[i].node.id)
          arrayNum.push(data[i].node.order)
        }
      }

      arrayNum.sort((a, b) => a - b)

      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      let query = ` mutation
      {`

      for (let i = 0; i < result.length; i += 1) {
        if (this.enlace) {
          query += `
              m${i + 1}: updateLinkCategory(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                          linkCategory{
                          id
                          order
                          linkCategory
                          }
                        }
            `
        } else {
          query += `
          m${i + 1}: updateCategory(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                      category{
                        id
                        name
                        order
                      }
                    }
                  `
        }
      }
      query += `
      }`
      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.changeTypeOrder()
          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      const userData = getUserData()
      axios
        .post('', {
          query: `
              mutation{
            createCategory(input:{name:"${this.name}",isActive:false,state: RED,client:"${userData.profile.client.id}",parent:"${this.code}",isFinal:true,orderType:${this.orderType},type:${this.contentData.type}}){
              category {
                id
                name

              }
            }
          }`,
        })
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.moveToCategory(this.contentData.categoryContent.edges, this.code, res.data.data.createCategory.category.id)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
        })
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    onCopy() {
      showToast(this.$t('code.tCopy'), 1, this)
    },
    onError() {
      showToast(this.$t('code.errorCopy'), 2, this)
    },
    generateXml(idCategory) {
      const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
      let host = ''
      switch (mode) {
        case 'develop':
          host = 'https://develop.interactvty.com'
          break
        case 'sandbox':
          host = 'https://sandbox.interactvty.com'
          break
        case 'production':
          host = 'https://api.interactvty.com'
          break
        default:
          host = 'http://localhost:8000'
          break
      }

      const clientName = this.userData.profile.client.name
      this.feedPodcast = `${host}/api/2.0/contents/feed_podcasts/?category_id=${idCategory}&client=${clientName}`
    },
    moveToCategory(categoryContent, idParent, newCat) {
      const encondedCode = utf8ToB64(`id:${idParent}`)
      let query = ` mutation
            {`
      for (let i = 0; i < categoryContent.length; i += 1) {
        const categories = categoryContent[i].node.categories.edges.filter(
          element => element.node.id !== idParent,
        )

        const res = []
        categories.forEach(item => res.push(item.node.id))
        res.push(newCat)

        query += `
                       ${(i + 1) === categoryContent.length ? 'final' : `m${i + 1}`}: updateContents(id: "${categoryContent[i].node.id}", input:{
                        categories: [${res}]
                      }) {
                        content{
                          id
                          name
                          client {
                            id
                            name
                          }
                        }
                      }
                        `
      }
      query += `
            }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          axios
            .post('', {
              query: `
               mutation
               {
                updateCategory(id:"${idParent}",input:{isFinal:false,orderType:MAN})
                {
                    category{
                  id
                  name
                  order
                  orderType
                  image
                  imageUrl
                  mediaLocation
                  isFinal
                  type

                  categoryContent(orderBy:"order,-created_at"){
                  totalCount
                  edgeCount
                  edges
                  {
                    node
                    {
                      id
                      type
                       name     
                      emisionDate
                      contentContentOrder(category:"${encondedCode}") {
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                      publishDate
                      expirationDate

                      isActive
                      order
                      mediaLocation
                      image
                      imageUrl
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                   }
                  }
                }
                  childCategories{
                    edges {
                      node {
                        id
                        name
                        categoryContent(orderBy:"order,-created_at"){
                            totalCount
                            edgeCount
                            edges
                            {
                              node
                              {
                                id
                                type
                                name
                                emisionDate
                                contentContentOrder(category:"${encondedCode}") {
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                                modifiedAt
                                publishDate
                                expirationDate
                                isAds
                                isActive
                                order
                                mediaLocation
                                image
                                imageUrl
                                categories {
                                  edges {
                                    node {
                                      id
                                      name
                                    }
                                  }
                                }
                            }
                            }
                          }
                        description
                        type
                        isActive
                        isPremium
                        name
                        mediaLocation
                        image
                        imageUrl
                        isFinal
                        parent {
                          id
                          name
                          description
                          mediaLocation
                          image
                          imageUrl
                          isFinal
                        }

                      }
                    }
                  }
                  parent {
                    id
                    name
                    description
                    isFinal
                  }
                }
                }
              }`,
            }).then(result => {
              messageError(result, this)

              try {
                const catCont = result.data.data.updateCategory.category
                this.contentData = catCont

                this.reloadData(this.code)
              } catch (error) {
                console.log(error)
              }
            }).catch(err => {
              console.log(err)

              showToast(this.$t('error'), 0, this)
            })
        })
        .catch(res => {
          console.log(res)
          showToast(this.$t('error'), 0, this)
        })
    },
    addLinkCategory(item) {
      const order = this.lastOrder
      this.hideModal('enlace')
      axios
        .post('', {
          query: `
          mutation{
             createLinkCategory(input:{parentCategory:"${this.code}",linkCategory:"${item === null ? null : item.node.id}",order:${order}}){
              linkCategory{
                id
                order

              }
            }
          }`,
        }).then(res => {
          messageError(res, this)

          this.reloadData(this.code)
        }).catch(() => {

        })
    },
    catEspecial(cat) {
      this.$swal({
        title: this.$t('specialCategoryTitle'),
        text: this.$t('specialCategoryMsg', { category_name: cat.node.name, category_type: this.$t(this.categoryTypes[cat.node.type]) }),
        icon: 'info',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {

      }).catch(() => {

      })
    },
    getCategory(id) {
      this.seleccionar = false
      this.todos = false
      const encondedCode = utf8ToB64(`id:${id}`)
      this.code = id
      const { headers } = this

      this.overlay = true
      axios
        .post('', {
          query: `
          query {
            allCategories(id:"${id}"
            )  {
            totalCount
                    edges {
                      node {
                        id
                        isActive
                        name
                        orderType
                        isFinal
                        type                         
                        parent {
                          id
                          name
                          type
                        }
                         childCategories {
                           totalCount
                            edgeCount
                          edges {
                            node {
                            id
                            name
                            order
                            image
                            imageUrl
                            mediaLocation
                            isFinal
                            isActive
                            type
                            parent {
                              id
                              name
                              isFinal
                            }
                            }
                          }
                        }
                        categoryContentOrder {
                          totalCount
                        }
                        categoryContent(orderBy:"order,-created_at"){
                           totalCount
                            edgeCount
                          edges {
                            node {
                              id
                              name
                              type
                              emisionDate
                              modifiedAt
                              publishDate
                              expirationDate
                              isActive
                              order
                              state
                              mediaLocation
                              image
                              imageUrl
                              categories {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                            contentContentOrder(category:"${encondedCode}") {
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
              }
          }
      }

        `,
        }, { headers }).then(result => {
          messageError(result, this)

          const actualCategory = result.data.data.allCategories.edges[0].node
          this.isContent = actualCategory.isFinal
          if (this.isContent) this.getCheckCat(this.code)

          this.orderType = actualCategory.orderType
          this.enlace = actualCategory.type === 'LIN'

          this.idParent = actualCategory.parent != null ? actualCategory.parent.id : null
          this.fromSyP = actualCategory.parent != null ? !!(actualCategory.parent.type === 'SER' || actualCategory.parent.type === 'PRO') : false

          this.brItems.push({
            text: actualCategory.name,
            id: actualCategory.id,
          })

          this.navItems = this.brItems.slice()

          if (this.isContent) {
            this.contentData = actualCategory

            this.totalContents = this.contentData.categoryContent.totalCount
            try {
              this.contentData.categoryContent.edges = this.contentData.categoryContent.edges.sort(
                (a, b) => a.node.contentContentOrder.edges[0].node.order - b.node.contentContentOrder.edges[0].node.order,
              )
              if (this.totalContents !== this.contentData.categoryContentOrder.totalCount) {
                checkRelationOrder(this.code).then(() => {
                  this.reloadData(this.code)
                }).catch(err => {
                  console.log(err)
                })
              }
            } catch (error) {
              checkRelationOrder(this.code).then(() => {
                this.reloadData(this.code)
              }).catch(err => {
                console.log(err)
              })
            }
          } else {
            this.childCategory = actualCategory.childCategories.edges
            this.totalCategories = actualCategory.childCategories.totalCount
            this.childCategory = this.childCategory.sort((a, b) => (a.node.order - b.node.order))
            this.contentData = []
          }

          this.overlay = false
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }).catch(err => {
          console.log(err)
          this.overlay = false
        })
    },
    removeLink(link) {
      axios
        .post('', {
          query: `
          mutation{
            deleteLinkCategory(id:"${link.node.linkLinkCategory.edges[0].node.id}"){
              found
              deletedId
            }
          }
        `,
        }).then(result => {
          messageError(result, this)

          this.reloadData(this.code)
          showToast(this.$t('success'), 1, this)
        }).catch(() => {
          showToast(this.$t('error'), 0, this)
        })
    },

    contChoice(data) {
      this.choicesContents = data
    },
    status() {
      let isActive = false
      switch (this.state) {
        case 'GRE':
          isActive = true
          break
        case 'YEW':
          isActive = true

          break
        case 'RED':
          isActive = false
          break
        case 'BCK':
          isActive = false
          break

        default:
          break
      }

      let query = 'mutation{'
      this.choicesContents.forEach((element, i) => {
        query += `
          m${i + 1}:updateContents(id:"${element}",input:{state:${this.state
},isActive:${isActive}}){
            content {
              id
              isActive
            }
          }

        `
      })
      query += '}'

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          this.reloadData(this.code)
        }).catch(err => {
          console.log(err)
        })
      let estado = ''
      switch (this.state) {
        case 'GRE':
          estado = this.$t('ecommerce.public')
          break
        case 'YEW':
          estado = this.$t('dataGeneric.internal')

          break
        case 'RED':
          estado = this.$t('ecommerce.NoPublic')

          break

        default:
          break
      }
      if (this.choicesContents.length >= 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              this.choicesContents.length === 1
                ? `${this.$t('statusContentCh')} ${estado}` : `${this.$t('statusContentsCh')}  ${estado}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }
    },
    actReturn(data) {
      this.actions = data
    },
    vacio() {
      this.totalContents = 0
      this.contentData.categoryContent.totalCount = 0
      this.seleccionar = false
      this.todos = false
    },

    recursive(id) {
      const { headers } = this
      axios
        .post('', {
          query: `
            query {
              allCategories(id:"${id}",type:POD)  {
              totalCount
                      edges {
                        node {
                          id
                          name
                          parent {
                            id
                            name
                            isFinal
                          }
                          linkCategories {
                          totalCount
                          edges {
                            node {
                              id
                              name
                              order
                              isFinal
                              isActive
                              type
                              linkLinkCategory {
                                edges {
                                  node {
                                    id
                                    order
                                    
                                  }
                                }
                              }
                            }
                          }
                        }
                        }
                }
            }
        }
          `,
        }, { headers })
        .then(result => {
          messageError(result, this)

          if (this.enlace) {
            if (result.data.data.allCategories.edges[0].node.linkCategories != null) {
              const i = result.data.data.allCategories.edges[0].node.parent.id
              const n = result.data.data.allCategories.edges[0].node.parent.name

              this.brItems.splice(1, 0, {
                text: n,
                id: i,
              })
              this.recursive(i)
            }
          } else if (result.data.data.allCategories.edges[0].node.parent != null) {
            const i = result.data.data.allCategories.edges[0].node.parent.id
            const n = result.data.data.allCategories.edges[0].node.parent.name

            this.brItems.splice(1, 0, {
              text: n,
              id: i,
            })
            this.recursive(i)
          } else {
            this.navItems = this.brItems.slice()
          }
        })
        .catch(() => { })
    },
    navigationLink(id) {
      const { headers } = this

      axios
        .post('', {
          query: `
            query {
              allCategories(id:"${id}")  {
              totalCount
                      edges {
                        node {
                          id
                          name
                          parent {
                            id
                            name
                            isFinal
                          }
                          linkCategoryCategory(id:"${this.idEnlace}") {
                            totalCount
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                  }

          `,
        }, { headers })
        .then(result => {
          messageError(result, this)
          const res = result.data.data.allCategories.edges[0].node

          try {
            if (res.linkCategoryCategory.totalCount > 0) {
              const i = res.linkCategoryCategory.edges[0].node.id
              const n = res.linkCategoryCategory.edges[0].node.name
              this.brItems.splice(1, 0, {
                text: n,
                id: i,
              })
              this.navItems = this.brItems.slice()
            } else if (res.parent != null) {
              const i = res.parent.id
              const n = res.parent.name
              this.brItems.splice(1, 0, {
                text: n,
                id: i,
              })
              this.navigationLink(i)
            } else {
              this.navItems = this.brItems.slice()
            }
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => { })
    },
    getElement(id = null) {
      if (id != null) {
        this.$router.push({ name: 'subcategories-podcast', params: { id } })
      } else {
        this.$router.push({ name: 'categories-podcast' })
      }
    },
    reloadData(id) {
      this.seleccionar = false
      this.todos = false
      const encondedCode = utf8ToB64(`id:${id}`)
      this.code = id
      const { headers } = this

      this.overlay = true
      axios
        .post('', {
          query: `
          query {
            allCategories(id:"${id}",type:POD,
            ,client:"${this.userData.profile.client.id}"
            )  {
            totalCount
                    edges {
                      node {
                        id
                        isActive
                        name
                        orderType
                        isFinal
                        type
                         linkCategories {
                          totalCount
                          edges {
                            node {
                              id
                              name
                              order
                              isFinal
                              isActive
                              type
                              linkLinkCategory(parentCategory:"${encondedCode}") {
                                edges {
                                  node {
                                    id
                                    order
                                  }
                                }
                              }
                            }
                          }
                        }
                        parent {
                          id
                          name
                        }
                         childCategories {
                           totalCount
                            edgeCount
                          edges {
                            node {
                            id
                            name
                            order
                            image
                            imageUrl
                            mediaLocation
                            isFinal
                            isActive
                            type
                            parent {
                              id
                              name
                              isFinal
                            }
                            }
                          }
                        }
                        categoryContent(orderBy:"order,-created_at"){
                           totalCount
                            edgeCount
                          edges {
                            node {
                              id
                              name
                              type
                              emisionDate
                              modifiedAt
                              publishDate
                              expirationDate
                              isActive
                              order
                              state
                              mediaLocation
                              image
                              imageUrl
                              categories {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                            contentContentOrder(category:"${encondedCode}") {
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
              }
          }
      }

        `,
        }, { headers }).then(result => {
          messageError(result, this)

          const actualCategory = result.data.data.allCategories.edges[0].node
          this.isContent = actualCategory.isFinal
          this.orderType = actualCategory.orderType
          this.enlace = actualCategory.type === 'LIN'
          this.idParent = actualCategory.parent != null ? actualCategory.parent.id : null

          if (this.isContent) {
            this.contentData = actualCategory

            this.totalContents = this.contentData.categoryContent.totalCount
            try {
              this.contentData.categoryContent.edges = this.contentData.categoryContent.edges.sort(
                (a, b) => a.node.contentContentOrder.edges[0].node.order - b.node.contentContentOrder.edges[0].node.order,
              )
              // eslint-disable-next-line no-empty
            } catch (error) {
            }
          } else {
            this.childCategory = actualCategory.childCategories.edges
            this.totalCategories = actualCategory.childCategories.totalCount
            this.childCategory = this.childCategory.sort((a, b) => (a.node.order - b.node.order))

            this.contentData = []
          }

          this.overlay = false
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }).catch(err => {
          console.log(err)
          this.overlay = false
        })
    },

  },

}
</script>

<style lang="scss" scoped>
#categorias .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#categorias .card-body {
  padding: 0rem;
}

#categorias .card-body h4 {
  font-size: 1rem !important;
}

#categorias .card-body .actions {

  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;

}

#categorias .card-body .actions .action {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

#categorias .dropContainer {
  border: 3px dashed;
}

#categorias .editIcon {
  color: darkcyan;
  margin-right: 10px;
}

#categorias .deleteIcon {
  color: red;
}

#categorias .breadcrumb {
  justify-content: flex-start;
  font-weight: bold;
}

#categorias #button-content {
  padding: 0px;
}

#categorias #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#categorias #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#categorias .carCat img {
  height: 11vw;
  object-fit: cover;
}
</style>
